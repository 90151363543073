/*-
 * *****
 * Concord
 * -----
 * Copyright (C) 2017 - 2020 Walmart Inc.
 * -----
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =====
 */
import * as React from 'react';

import { Loader } from 'semantic-ui-react';
import Editor from '@monaco-editor/react';
import { useCallback } from 'react';

interface LoadingEditorProps {
    language?: string;
    handleEditorDidMount: (getEditorValue: () => string) => void;
    initValue?: string;
    disabled: boolean;
}

const LoadingEditor = ({
    language,
    handleEditorDidMount,
    initValue,
    disabled
}: LoadingEditorProps) => {
    const onEditorDidMount = useCallback(
        (editor) => {
            handleEditorDidMount(() => editor.getValue());
        },
        [handleEditorDidMount]
    );

    if (!initValue) {
        return <Loader active={true} />;
    }

    return (
        <Editor
            language={language}
            onMount={onEditorDidMount}
            value={initValue}
            options={{ lineNumbers: 'on', minimap: { enabled: false }, readOnly: disabled }}
        />
    );
};

export default LoadingEditor;
